const Drying = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <title>asciugatura</title>
      <circle className="fill-[#6bc5e7]" cx="256" cy="256" r="256" />
      <path
        className="fill-[#0c739a]"
        d="M512,256a256.58,256.58,0,0,0-6.91-59.26L431.38,123l-82.06,22h0l-142.54-38.4A102.95,102.95,0,0,0,101.51,276.25l46,46v93.1a32.36,32.36,0,0,0,8.23,21.6l72.53,73.58A258.78,258.78,0,0,0,256,512C397.39,512,512,397.39,512,256Z"
      />
      <rect
        className="fill-[#ffdc00]"
        x="218.5"
        y="322.41"
        width="20.91"
        height="31.4"
      />
      <path
        className="fill-[#00b8c0]"
        d="M190.62,447.82H180a32.51,32.51,0,0,1-32.5-32.5V282.48h75.59V415.32A32.51,32.51,0,0,1,190.62,447.82Z"
      />
      <polygon
        className="fill-[#00e8f2]"
        points="349.32 145.04 339.12 145.04 339.12 264.5 349.32 264.5 431.38 286.51 431.38 123.03 349.32 145.04"
      />
      <polygon
        className="fill-[#00b8c0]"
        points="431.38 204.77 431.38 286.51 349.32 264.5 339.12 264.5 339.12 204.77 431.38 204.77"
      />
      <path
        className="fill-white"
        d="M349.32,264.5V145l-142.54-38.4A102.94,102.94,0,1,0,198,305.25h0l.13,0c2.89-.64,5.72-1.41,8.51-2.29Z"
      />
      <path
        className="fill-[#fff5ca]"
        d="M349.32,204.77V264.5L206.66,302.93c-2.78.88-5.62,1.65-8.51,2.29l-.13,0h0A103,103,0,0,1,72.62,204.77Z"
      />
      <path
        className="fill-[#00e8f2]"
        d="M113.55,204.76a62.57,62.57,0,0,0,.5,7.8h123a61,61,0,0,0,0-15.59h-123A62.53,62.53,0,0,0,113.55,204.76Z"
      />
      <path
        className="fill-[#00b8c0]"
        d="M237.59,204.77a62.53,62.53,0,0,1-.5,7.79h-123a62.53,62.53,0,0,1-.5-7.79Z"
      />
      <path
        className="fill-[#00e8f2]"
        d="M175.57,142.74A61.74,61.74,0,0,0,135,157.9h81.2A61.74,61.74,0,0,0,175.57,142.74Z"
      />
      <path
        className="fill-[#00b8c0]"
        d="M175.57,266.79a61.74,61.74,0,0,0,40.6-15.16H135A61.74,61.74,0,0,0,175.57,266.79Z"
      />
      <path
        className="fill-[#00b8c0]"
        d="M124.46,239.9H226.68a62,62,0,0,0,7.76-15.6H116.71A61.77,61.77,0,0,0,124.46,239.9Z"
      />
      <path
        className="fill-[#00e8f2]"
        d="M124.46,169.63a61.61,61.61,0,0,0-7.75,15.6H234.44a62,62,0,0,0-7.76-15.6Z"
      />
    </svg>
  );
};

export default Drying;
