const Disinfection = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <title>sanificazione</title>
      <circle className="fill-[#6bc5e7]" cx="256" cy="256" r="256" />
      <path
        className="fill-[#0c739a]"
        d="M512,256q0-7.56-.43-15L320,49.41,157,82.47l94.81,94.81L153.62,445l64.23,64.23A258.09,258.09,0,0,0,256,512C397.38,512,512,397.39,512,256Z"
      />
      <path className="fill-[#d8f1fb]" d="M232.24,64.41h47.52V139H232.24Z" />
      <path className="fill-[#bceaf9]" d="M256,64.41h23.76V139H256Z" />
      <path className="fill-[#0a617d]" d="M192,134H320V203H192Z" />
      <path className="fill-[#08475e]" d="M256,134h64V203H256Z" />
      <path
        className="fill-white"
        d="M182.24,98.73,157,82.47l6.65-10.32a49.46,49.46,0,0,1,41.73-22.74H320v30H205.41a19.6,19.6,0,0,0-16.52,9Z"
      />
      <path className="fill-[#bceaf9]" d="M256,49.41h64v30H256Z" />
      <path
        className="fill-white"
        d="M322.21,458.59H189.79A54.78,54.78,0,0,1,135,403.81V254.12a54.77,54.77,0,0,1,54.78-54.78H322.21A54.77,54.77,0,0,1,377,254.12V403.81A54.78,54.78,0,0,1,322.21,458.59Z"
      />
      <path
        className="fill-[#bceaf9]"
        d="M377,254.12V403.81a54.78,54.78,0,0,1-54.78,54.78H256V199.34h66.21A54.77,54.77,0,0,1,377,254.12Z"
      />
      <path
        className="fill-[#ff4a4a]"
        d="M304.74,305.66H272.9V273.82H239.11v31.84H207.26v33.79h31.85V371.3H272.9V339.45h31.84Z"
      />
      <path
        className="fill-[#d01273]"
        d="M256,273.82V371.3h16.93V339.45h31.85V305.66H272.89V273.82Z"
      />
    </svg>
  );
};

export default Disinfection;
