const Shampoo = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 510.96"
    >
      <title>shampoo</title>
      <path
        className="fill-[#6095c4]"
        d="M511,256q0,11-.93,21.86A255.48,255.48,0,0,1,0,256q0-9.27.66-18.37C10.07,105.1,120.57.52,255.48.52,396.58.52,511,114.9,511,256Z"
        transform="translate(0 -0.52)"
      />
      <rect
        className="fill-[#d8d8d8]"
        x="234.61"
        y="27.39"
        width="41.73"
        height="71.95"
      />
      <rect
        className="fill-[#eaeaea]"
        x="184.57"
        y="78.49"
        width="141.82"
        height="81.34"
      />
      <path
        className="fill-[#eaeaea]"
        d="M510,277.86a255.72,255.72,0,0,1-318.3,225.6h0C81.47,475.14,0,375.08,0,256q0-9.27.66-18.37a45.92,45.92,0,0,1,49.23,1.63,57,57,0,0,1,100.2,54.56H320a57,57,0,0,1,111.73-22.36,53.4,53.4,0,0,1,78.29,6.4Z"
        transform="translate(0 -0.52)"
      />
      <rect
        className="fill-[#d8d8d8]"
        x="184.57"
        y="78.49"
        width="48.99"
        height="81.34"
      />
      <rect
        className="fill-[#eaeaea]"
        x="210.68"
        width="89.58"
        height="51.38"
      />
      <rect
        className="fill-[#d8d8d8]"
        x="210.68"
        width="30.94"
        height="51.38"
      />
      <path
        className="fill-[#3aa5c9]"
        d="M402.51,182.09V465a255.24,255.24,0,0,1-294.06,0V182.09a43.92,43.92,0,0,1,43.92-43.92H358.59A43.92,43.92,0,0,1,402.51,182.09Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#60c5e8]"
        d="M402.51,182.09V465a256.08,256.08,0,0,1-210.78,38.51h0a43.86,43.86,0,0,1-18.62-35.9V182.09A43.92,43.92,0,0,1,217,138.17H358.59A43.92,43.92,0,0,1,402.51,182.09Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-white"
        d="M255,305.63a50.63,50.63,0,0,1,50.63,50.63c0,28-22.67,31.74-50.63,31.74s-50.64-3.77-50.64-31.74A50.63,50.63,0,0,1,255,305.63Z"
        transform="translate(0 -0.52)"
      />
      <circle className="fill-white" cx="225.28" cy="268.78" r="21.05" />
      <circle className="fill-white" cx="177.87" cy="305.74" r="21.05" />
      <circle className="fill-white" cx="333.08" cy="307.39" r="21.05" />
      <circle className="fill-white" cx="286.47" cy="269.43" r="21.05" />
      <path
        className="fill-[#d8d8d8]"
        d="M512,112.38a37.5,37.5,0,1,1-29.24-36.62A37.56,37.56,0,0,1,512,112.38Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#eaeaea]"
        d="M512,112.38A37.56,37.56,0,0,1,482.76,149a37.55,37.55,0,0,1,0-73.24A37.56,37.56,0,0,1,512,112.38Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#d8d8d8]"
        d="M108.8,117.36a48.59,48.59,0,1,1-36.15-47A48.68,48.68,0,0,1,108.8,117.36Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#eaeaea]"
        d="M108.8,117.36a48.68,48.68,0,0,1-36.15,47.05,48.68,48.68,0,0,1,0-94.09A48.69,48.69,0,0,1,108.8,117.36Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#d8d8d8]"
        d="M414,56.07a18.74,18.74,0,0,1-11.21,17.17,18.36,18.36,0,0,1-7.56,1.6,18.77,18.77,0,1,1,0-37.54,18.52,18.52,0,0,1,7.56,1.59A18.77,18.77,0,0,1,414,56.07Z"
        transform="translate(0 -0.52)"
      />
      <path
        className="fill-[#eaeaea]"
        d="M414,56.07a18.74,18.74,0,0,1-11.21,17.17,18.75,18.75,0,0,1,0-34.34A18.74,18.74,0,0,1,414,56.07Z"
        transform="translate(0 -0.52)"
      />
    </svg>
  );
};

export default Shampoo;
