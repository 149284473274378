import { BiTime } from "react-icons/bi";
import { HiPhone, HiMail } from "react-icons/hi";
import { Social } from "../shared/Social";

export const TopBanner = () => {
  return (
    <div className="bg-section hidden xl:block">
      <div className="flex justify-between xl:px-8 container mx-auto">
        <span className="text-white flex items-center">
          <BiTime className="text-xl mr-3" />
          <span>Lunedì - Sabato: 9:00 - 18:00</span>
        </span>

        <a href="tel:3298798056" className="text-white flex items-center">
          <HiPhone className="text-xl" />
          <span className="ml-2">329 8798056</span>
        </a>

        <a
          href="mailto:dogshowersilvi@gmail.com"
          className="text-white flex items-center"
        >
          <HiMail className="text-xl" />
          <span className="ml-2">dogshowersilvi@gmail.com</span>
        </a>

        <Social contrast />
      </div>
    </div>
  );
};
