import FullSection from "../shared/FullSection";
import Header from "../shared/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useThumbnails } from "../../hooks/useThumbnails";
import { Modal } from "../shared/Modal";
import { useState } from "react";
import { Section } from "../../types/navbar";
import LazyLoad from "react-lazyload";

export const AnimalsSlider = () => {
  const thumbnails = useThumbnails();

  const [modal, setModal] = useState({ show: false, source: "" });

  return (
    <FullSection contrast section={Section.Gallery}>
      <Modal
        show={modal.show}
        source={modal.source}
        onCancel={() => setModal({ show: false, source: "" })}
      />
      <Header
        contrast
        text="I nostri clienti"
        subtitle="Scorri o clicca sulla foto per incontrare alcuni dei nostri amici a quattro zampe"
      />
      <div className="mt-16">
        <Swiper
          grabCursor={true}
          loop={false}
          spaceBetween={30}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1300: {
              slidesPerView: 4,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="animals-container"
        >
          {thumbnails.map((thumbnail, index) => (
            <SwiperSlide key={index}>
              <div
                className="single-animal"
                onClick={() => setModal({ show: true, source: thumbnail })}
              >
                <LazyLoad>
                  <img
                    src={thumbnail}
                    alt="Cane a lavaggio nel Dog Shower Point Silvi Marina"
                    className="rounded-[50%] border-2 border-white"
                  />
                </LazyLoad>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </FullSection>
  );
};
