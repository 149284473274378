export type NavLink = {
  title: string;
  section: Section;
};

export enum Section {
  About = "about",
  Services = "services",
  Fidelity = "fidelity",
  Location = "location",
  Gallery = "gallery",
  Testimonials = "testimonials",
  Contacts = "contacts",
}
