type Props = {
  show: boolean;
  source: string;
  onCancel: () => void;
};

export const Modal = ({ show, source, onCancel }: Props) => {
  if (!show) return null;

  return (
    <div
      className="relative z-max"
      aria-labelledby="modal-animal"
      role="dialog"
      aria-modal="true"
      onClick={onCancel}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-card text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-card px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex justify-center items-center">
                <img
                  src={source}
                  loading="lazy"
                  alt="Dettaglio Cane a lavaggio nel Dog Shower Point Silvi Marina"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
