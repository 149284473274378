import Disinfection from "../../../icons/Disinfection";
import Drying from "../../../icons/Drying";
import Shampoo from "../../../icons/Shampoo";
import Suction from "../../../icons/Suction";
import Card from "./Card";
import FullSection from "../../shared/FullSection";
import Header from "../../shared/Header";
import FiveDogs from "../../../image/five-dogs.png";
import { Section } from "../../../types/navbar";
import LazyLoad from "react-lazyload";

const content = [
  {
    id: 1,
    title: "Sanificazione",
    description: () => (
      <p className="text-md">
        Sanificazione della vasca con prodotti per garantire la{" "}
        <strong>massima pulizia e igiene</strong>.
      </p>
    ),
    icon: () => <Disinfection />,
  },
  {
    id: 2,
    title: "Detergenti",
    description: () => (
      <p className="text-md">
        Utilizziamo solo{" "}
        <strong>shampoo e balsamo delicati e non aggressivi</strong> formulati
        per le esigenze dei diversi tipi di pelo.
      </p>
    ),
    icon: () => <Shampoo />,
  },

  {
    id: 3,
    title: "Aspirazione",
    description: () => (
      <p className="text-md">
        Aspiratori di alta qualità per{" "}
        <strong>rimuovere efficacemente ogni traccia di acqua</strong>, peli
        morti e altri detriti dal manto del vostro cane.
      </p>
    ),
    icon: () => <Suction />,
  },

  {
    id: 4,
    title: "Asciugatura",
    description: () => (
      <p className="text-md">
        Una corretta asciugatura è importante per{" "}
        <strong>prevenire irritazioni e la formazione di cattivi odori</strong>.
      </p>
    ),
    icon: () => <Drying />,
  },
];

const Services = () => {
  return (
    <FullSection className="relative pb-48" section={Section.Services}>
      <Header text="Quali servizi offriamo" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-10 mt-16">
        {content.map((item) => (
          <Card
            key={item.id}
            description={<item.description />}
            img={<item.icon />}
            title={item.title}
          />
        ))}
      </div>

      <div className="w-full md:max-w-[500px] absolute bottom-0 left-1/2 -translate-x-1/2">
        <LazyLoad>
          <img
            src={FiveDogs}
            alt="Cinque cani contenti al dog shower point silvi"
          />
        </LazyLoad>
      </div>
    </FullSection>
  );
};

export default Services;
