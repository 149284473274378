import { useRef } from "react";
import { StartRate } from "./StartRate";
import { useInView, m } from "framer-motion";
import LazyLoad from "react-lazyload";

type Props = {
  source: string;
  title: string;
  description: string;
};

export const TestimonialCard = ({ description, source, title }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: false,
    margin: "0px 0px -100px 0px",
  });
  return (
    <m.div
      className="card p-10 bg-base-100 shadow-xl mb-12 lg:mb-0 flex flex-col items-center bg-card"
      ref={ref}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ ease: "linear" }}
    >
      <div className="flex justify-center mb-6">
        <LazyLoad>
          <img
            src={source}
            className="rounded-full shadow-lg w-16"
            alt="Testimonial su Facebook Dog Shower Point Silvi"
          />
        </LazyLoad>
      </div>
      <h3 className="text-lg font-bold mb-4">{title}</h3>
      <p className="mb-4">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-left"
          className="w-6 pr-2 inline-block"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
          ></path>
        </svg>
        <span
          className="text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </p>
      <ul className="flex justify-center mb-0 mt-auto">
        <li>
          <StartRate />
        </li>
        <li>
          <StartRate />
        </li>
        <li>
          <StartRate />
        </li>
        <li>
          <StartRate />
        </li>
        <li>
          <StartRate />
        </li>
      </ul>
    </m.div>
  );
};
