import { HiPhone, HiMail } from "react-icons/hi";

import FullSection from "../shared/FullSection";
import { Section } from "../../types/navbar";
import { MdLocationOn } from "react-icons/md";
import { Social } from "../shared/Social";

export const Footer = () => {
  return (
    <FullSection contrast section={Section.Contacts}>
      <footer className="grid sm:grid-cols-2 lg:grid-cols-3 gap-y-12">
        <div>
          <h4 className="text-white text-3xl mb-6">Contatti</h4>
          <div className="grid grid-rows-2 gap-y-1">
            <div className="grid gap-y-2">
              <a
                href="tel:3298798056"
                className="text-white flex items-center"
                aria-label="Telefono Dog Shower Point Silvi Marina"
              >
                <HiPhone className="text-xl" />
                <span className="ml-2">329 8798056</span>
              </a>

              <a
                href="mailto:dogshowersilvi@gmail.com"
                className="text-white flex items-center"
                aria-label="Email Dog Shower Point Silvi Marina"
              >
                <HiMail className="text-xl" />
                <span className="ml-2">dogshowersilvi@gmail.com</span>
              </a>
            </div>

            <Social contrast />
          </div>
        </div>

        <div className="text-white">
          <h4 className="text-white text-3xl mb-6">Dove trovarci</h4>
          <div className="grid grid-rows-2 gap-y-3">
            <h5>Via Turati 12, 64028 Silvi Marina (TE)</h5>
            <div className="underline cursor-pointer">
              <a
                href="https://www.google.it/maps/place/Dog+Shower+Point/@42.5430056,14.1210121,17z/data=!3m1!4b1!4m5!3m4!1s0x1331a31a760784c7:0x4059a946771d9f87!8m2!3d42.5430017!4d14.1232008"
                target="__blank"
                className="flex"
                aria-label="Dove Siamo Dog Shower Point Silvi Marina"
              >
                <MdLocationOn className="mr-2" fontSize={18} />
                Visualizza sulla mappa
              </a>
            </div>
          </div>
        </div>

        <div className="text-white">
          <h4 className="text-white text-3xl mb-6">Orari di apertura</h4>
          <div className="grid grid-rows-2 gap-y-3">
            <div>
              <h5>Senza Fidelity Card:</h5>
              <p>dal Lunedì al Sabato dalle 9:00 alle 18:00</p>
            </div>
            <div>
              <h5>Con Fidelity Card:</h5>
              <p>dal Lunedì alla Domenica dalle 9:00 alle 22:00</p>
            </div>
          </div>
        </div>
      </footer>
    </FullSection>
  );
};
