const Coins = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500.27 455.42"
      className="text-primary-content"
    >
      <title>monete</title>
      <ellipse
        className="cls-1"
        cx="231.39"
        cy="58.46"
        rx="225.71"
        ry="58.46"
      />
      <path
        className="cls-1"
        d="M71.44,201.52c45.11,13.25,101.91,21.06,160,20.55,128.08-1.12,188-31,188-31,24.36-10.31,38.24-21.74,38.24-32.15V111.48a11.24,11.24,0,0,0-.14-1.65c-7.92,13.17-27.51,23-45.56,29.72v30.06a7.5,7.5,0,0,1-15,0V144.55c-4.52,1.36-8.62,2.46-12,3.32v36.49a10,10,0,0,1-20,0V152.19c-37.46,7.07-83.58,11.25-133.49,11.25-118.6,0-215.93-23.57-226-53.61a10,10,0,0,0-.15,1.65v48.46C5.2,173.78,30,189.33,71.44,201.52Z"
        transform="translate(0 -22.42)"
      />
      <path
        className="cls-1"
        d="M500.12,238.92c-7.92,13.17-27.5,23-45.55,29.71V298.7a7.5,7.5,0,0,1-15,0V273.63C435,275,431,276.1,427.57,277v36.49a10,10,0,0,1-20,0V281.27c-37.46,7.07-83.59,11.25-133.5,11.25-118.6,0-215.93-23.56-226-53.6a11.24,11.24,0,0,0-.14,1.65V289c0,13.85,24.77,29.39,66.25,41.58,45.1,13.26,101.9,21.06,159.94,20.55,128.08-1.12,188-31,188-31,24.36-10.32,38.25-21.75,38.25-32.15V240.57A10,10,0,0,0,500.12,238.92Z"
        transform="translate(0 -22.42)"
      />
      <path
        className="cls-1"
        d="M226.19,419.19c-49.9,0-96-4.18-133.49-11.25v32.17a10,10,0,0,1-20,0V403.62c-3.38-.86-7.48-2-12-3.32v25.06a7.5,7.5,0,0,1-15,0V395.3C27.65,388.63,8.06,378.76.15,365.58A10,10,0,0,0,0,367.23v47.42c0,10.41,13.88,21.84,38.24,32.15,0,0,59.87,29.9,188,31,58.05.51,114.85-7.3,160-20.55,41.48-12.19,66.25-27.74,66.25-41.59V367.23a9.9,9.9,0,0,0-.15-1.64C442.13,395.62,344.79,419.19,226.19,419.19Z"
        transform="translate(0 -22.42)"
      />
      <path
        className="cls-1"
        d="M238.84,244.17c-58,1.56-114.84-7.3-159.94-20.55A269,269,0,0,1,49,213c4.43,31.28,103.7,56.28,225.54,56.28,124.66,0,225.72-26.17,225.72-58.46,0-11.54-12.91-22.29-35.17-31.35V181c0,10.93-15.31,23-42,33.7C423.05,214.7,364.33,240.8,238.84,244.17Z"
        transform="translate(0 -22.42)"
      />
      <path
        className="cls-1"
        d="M226.78,396.08c121.84,0,221.11-25,225.54-56.28a269,269,0,0,1-29.95,10.59c-45.1,13.25-101.92,22.11-159.94,20.55-125.5-3.37-184.2-29.47-184.2-29.47-26.69-10.71-42-22.77-42-33.7v-1.5C14,315.33,1.06,326.09,1.06,337.62,1.06,369.91,102.12,396.08,226.78,396.08Z"
        transform="translate(0 -22.42)"
      />
    </svg>
  );
};

export default Coins;
