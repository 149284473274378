import { useInView, m } from "framer-motion";
import { AboutItemType } from "../../../types/sections";
import { useRef } from "react";

type Props = {
  item: AboutItemType;
};

export const AboutItem = ({ item }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -100px 0px",
  });
  return (
    <m.div
      className="w-full flex mt-10 px-4 lg:pl-12 lg:pr-0"
      ref={ref}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ ease: "linear" }}
    >
      <div className="w-full max-w-[40px] mr-6 fill-white">
        <item.icon />
      </div>
      <div className="">
        <h3 className="text-2xl mb-2 text-primary-content">{item.title}</h3>
        <item.description />
      </div>
    </m.div>
  );
};
