import FullSection from "../shared/FullSection";
import Header from "../shared/Header";
import FidelityCard from "../../image/dsp-card.jpg";
import Tick from "../../icons/Tick";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Section } from "../../types/navbar";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInView, m } from "framer-motion";
import LazyLoad from "react-lazyload";

const content = [
  {
    id: 1,
    title:
      "Possibilità di accedere al locale 7 giorni su 7 dalle 9:00 alle 22:00",
  },
  {
    id: 2,
    title:
      "Dimentica i gettoni! Potrai ricaricare la tessera presso il nostro distributore",
  },
  {
    id: 3,
    title:
      "Sconti esclusivi sui nostri servizi risparmiando sui costi di lavaggio",
  },

  {
    id: 4,
    title: "Promozioni dedicate",
  },
];

const Fidelity = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const margin = useMemo(() => {
    if (width <= 768) {
      return "-200px 0px -200px 0px";
    }
    return "-400px 0px -400px 0px";
  }, [width]);

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: false,
    margin,
  });
  return (
    <FullSection contrast section={Section.Fidelity}>
      <Header
        text="Fidelity card"
        subtitle="Richiedi in negozio la tua Fidelity Card per i seguenti vantaggi:"
        className="lg:flex"
        contrast
      />
      <div className="grid grid-cols-1 lg:grid-cols-8 mt-8 lg:mt-16" ref={ref}>
        <div className="lg:col-span-4 flex items-center">
          <div className="w-10/12 flex justify-center items-center mx-auto">
            <m.div
              className="fidelity-wrapper flex items-center"
              style={{
                transform: isInView
                  ? "scale(1) rotateY(0deg) rotateX(0deg) translateZ(0rem)"
                  : "scale(1) rotateY(-30deg) rotateX(45deg) translateZ(4.5rem)",
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)",
              }}
            >
              <LazyLoad>
                <img
                  src={FidelityCard}
                  className="max-w-full rounded-xl"
                  alt="Fidelity card del Dog Shower Point Silvi per sconti"
                />
              </LazyLoad>
            </m.div>
          </div>
        </div>
        <div className="lg:col-span-4 max-h-full px-4 mt-10 lg:mt-0">
          <div className="flex w-full h-full flex-col justify-between">
            {content.map((element) => (
              <div className="w-full flex items-center mb-7" key={element.id}>
                <Tick />
                <p className="text-md ml-2 text-white">{element.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-md flex justify-center mx-auto mt-6 lg:mt-12">
        <a
          href="https://wa.me/+393298798056"
          className="btn btn-outline btn-white rounded-xl btn-lg"
          target="__blank"
        >
          <AiOutlineWhatsApp className="mr-2 text-xl" fontSize={18} />
          Richiedi Fidelity Card
        </a>
      </div>
    </FullSection>
  );
};

export default Fidelity;
