import { useInView, m } from "framer-motion";
import { useRef } from "react";

type Props = {
  title: string;
  description: React.ReactNode;
  img: React.ReactNode;
};

const Card = ({ title, description, img }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: false,
    margin: "0px 0px -100px 0px",
  });
  return (
    <m.div
      className="w-10/12 max-w-[280px] mx-auto rounded-2xl shadow-xl flex flex-col justify-between items-center"
      ref={ref}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ ease: "linear" }}
    >
      <div className="flex flex-col items-center relative w-full h-[40%] rounded-tl-2xl rounded-tr-2xl bg-primary">
        <h3 className="text-center text-white font-bold text-2xl mt-5">
          {title}
        </h3>
        <div className="w-[100px] h-[100px] rounded-[50%] bg-white relative top-[40px] shadow-2xl">
          {img}
        </div>
      </div>
      <div className="h-full pt-[90px] px-[20px] pb-14 bg-card rounded-b-2xl">
        {description}
      </div>
    </m.div>
  );
};

export default Card;
