import Coins from "../../../icons/Coins";
import Equipment from "../../../icons/Equipment";
import Schedule from "../../../icons/Schedule";
import Tub from "../../../icons/Tub";
import Dog from "../../../image/cane-in-acqua.png";
import Header from "../../shared/Header";
import FullSection from "../../shared/FullSection";
import { Section } from "../../../types/navbar";
import { AboutItem } from "./AboutItem";
import { AboutItemType } from "../../../types/sections";
import LazyLoad from "react-lazyload";

const content: AboutItemType[] = [
  {
    id: 1,
    title: "Nessuna prenotazione",
    description: () => (
      <p className="text-md text-primary-content">
        La vita odierna può essere frenetica e rende difficile pianificare in
        anticipo. Per questo motivo,{" "}
        <strong>
          offriamo un servizio di lavaggio per il tuo animale domestico senza
          prenotazione
        </strong>
        . Vieni quando vuoi e lavalo in breve tempo!
      </p>
    ),
    icon: () => <Schedule />,
  },
  {
    id: 2,
    title: "Basso costo",
    description: () => (
      <p className="text-md text-primary-content">
        Il risparmio è la nostra priorità, senza compromettere la salute e il
        benessere degli animali domestici. Siamo convinti che{" "}
        <strong>basta poco per far felice il tuo amico a quattro zampe</strong>{" "}
        e siamo qui per aiutarti a farlo al meglio.
      </p>
    ),
    icon: () => <Coins />,
  },
  {
    id: 3,
    title: "Ambiente accogliente",
    description: () => (
      <p className="text-md text-primary-content">
        Curiamo molto l'ambiente, ci teniamo a mantenerlo sempre pulito e
        accogliente utilizzando{" "}
        <strong>prodotti eco-friendly e tecnologie avanzate</strong> per la
        pulizia e la disinfezione.
      </p>
    ),
    icon: () => <Tub />,
  },
  {
    id: 4,
    title: "Attrezzature di qualità",
    description: () => (
      <p className="text-md text-primary-content">
        Utilizziamo attrezzature di alta qualità per garantire la massima
        pulizia possibile per il tuo amico a quattro zampe. Ci impegniamo a
        fornire un <strong>servizio di qualità e</strong> a garantire{" "}
        <strong>risultati eccellenti</strong> e soddisfacenti per i nostri
        clienti.
      </p>
    ),

    icon: () => <Equipment />,
  },
];

const About = () => {
  return (
    <FullSection contrast section={Section.About}>
      <Header
        text="Perchè scegliere un lavaggio self-service?"
        className="hidden lg:flex"
        contrast
      />
      <div className="h-full">
        <div className="grid grid-cols-1 lg:grid-cols-7 lg:mt-6">
          <div className="order-1 lg:order-1 lg:col-span-4">
            <Header
              className="lg:hidden"
              text="Perchè scegliere un lavaggio self-service?"
              contrast
            />

            {content.map((item) => (
              <AboutItem item={item} key={item.id} />
            ))}
          </div>
          <div className="mt-12 lg:mt-0 max-w-[600px] order-2 lg:order-2 lg:col-span-3 flex items-center justify-end translate-x-1/4 md:translate-x-1/2 lg:translate-x-1/4 2xl:translate-x-64">
            <LazyLoad>
              <img
                src={Dog}
                className="w-full max-w-full"
                alt="Lavaggio cane con pallina in bocca"
              />
            </LazyLoad>
          </div>
        </div>
      </div>
    </FullSection>
  );
};

export default About;
