const Suction = () => {
  return (
    <svg
      id="Livello_1"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 546.88 546.88"
    >
      <title>aspirazione</title>
      <circle className="fill-[#1b313a]" cx="273.44" cy="273.44" r="273.44" />
      <path
        className="fill-[#285680]"
        d="M531.18,228l-71.71,14-75.21-14c0-15.46,32.88-28,73.46-28S531.18,212.55,531.18,228Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#84b5cb]"
        d="M531.18,228V305a21,21,0,0,1-21,21h-1.12v70H410.49V326h-5.24a21,21,0,0,1-21-21V228Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9fc9d3]"
        d="M287.92,167.42,282.47,204l-34.64.68L228.32,166l32.53-17.56,26-7.9Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#f3d55b]"
        d="M384.12,73.33l27.07,19a16.59,16.59,0,0,1,4.06,23.09,16.17,16.17,0,0,1-10.63,6.72,16.45,16.45,0,0,1-12.39-2.66l-27.07-19Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#2c3e50]"
        d="M384.26,423.9c-29.8,0-74.42,0-108.19-12.6a203.05,203.05,0,0,1-66.71,12.6c-40.51,0-68.8-11.2-79.7-31.55-7.65-14.3-5.6-31.62,5.85-48.79a7,7,0,1,1,11.64,7.75c-6,9-11.71,22.21-5.16,34.43,8.26,15.36,32.8,24.17,67.37,24.17a204.31,204.31,0,0,0,48.9-7.27,54.74,54.74,0,0,1-27.91-48.7c0-32.19,22.87-49,45.47-49s45.47,16.79,45.47,49a56,56,0,0,1-27.59,48.21c29.95,7.76,67,7.76,90.56,7.76a7,7,0,1,1,0,14ZM275.82,319c-15.16,0-31.48,10.95-31.48,35,0,19,10.32,32.88,31.52,42.17,20.86-9.8,31.44-24,31.44-42.17a35.68,35.68,0,0,0-9.9-26.46A30.78,30.78,0,0,0,275.82,319Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M204.56,206.89a6.91,6.91,0,0,1-4-1.26l-6.77-4.76a7,7,0,1,1,8-11.45l6.77,4.75a7,7,0,0,1-4,12.72Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#41767f]"
        d="M230.76,277.75a33.05,33.05,0,0,0-54.63-37.22L228.32,166l54.15,38Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#41767f]"
        d="M230.14,278.73l-45.48,64.85a28,28,0,0,1-39,6.86l-8.39-5.81a28.09,28.09,0,0,1-6.86-39l45.69-65.13a33.08,33.08,0,0,0,54,38.2Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#41767f]"
        d="M475.21,374.92v35a14,14,0,0,0,14,14h42v14a21,21,0,0,1-21,21H405.25a21,21,0,0,1-21-21v-14h42a14,14,0,0,0,14-14v-35a14,14,0,0,0-14-14h-42v-14a21,21,0,0,1,21-21H510.19a21,21,0,0,1,21,21v14h-42A14,14,0,0,0,475.21,374.92Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#84b5cb]"
        d="M388.18,96.34a16.52,16.52,0,0,1-23,4.06l-6.79-4.76L351.59,91a8.18,8.18,0,0,0-11.48,2l-52.19,74.44-27.07-19,56.88-81.29a33.14,33.14,0,0,1,46.1-8.05l13.5,9.45,6.79,4.76a16.51,16.51,0,0,1,4.06,23Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M230.76,277.75c-.13.28-.35.49-.48.77-.07.07-.14.14-.14.21a34.4,34.4,0,1,1,.62-1Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9fc9d3]"
        d="M419.24,263h-7a7,7,0,0,1,0-14h7a7,7,0,1,1,0,14Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9fc9d3]"
        d="M489.2,263h-42a7,7,0,1,1,0-14h42a7,7,0,1,1,0,14Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M440.23,374.92v35a14,14,0,0,1-14,14h-42v-63h42A14,14,0,0,1,440.23,374.92Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M531.18,360.93v63h-42a14,14,0,0,1-14-14v-35a14,14,0,0,1,14-14Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#46b29d]"
        d="M419.24,409.91a7,7,0,0,1-7-7v-21a7,7,0,0,1,14,0v21A7,7,0,0,1,419.24,409.91Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#46b29d]"
        d="M496.2,409.91a7,7,0,0,1-7-7v-21a7,7,0,1,1,14,0v21A7,7,0,0,1,496.2,409.91Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M433.23,186a7,7,0,0,1-7-7c0-22.66-9.87-28.56-10.29-28.81a7.08,7.08,0,0,1-2.8-9.42,6.93,6.93,0,0,1,9.2-3c1.85.92,17.88,9.85,17.88,41.24A7,7,0,0,1,433.23,186Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M444.92,116.07a22.37,22.37,0,0,1-6.9-.94,7,7,0,0,1,4.26-13.29c.7.21,11.82,2.7,27.63-13.1a7,7,0,1,1,9.9,9.89C465.1,113.34,452.39,116.07,444.92,116.07Z"
        transform="translate(-60.11 -4.31)"
      />
      <path
        className="fill-[#9bc8c2]"
        d="M470.72,152.31a7.05,7.05,0,0,1-3.12-.74l-30.5-15.21a7,7,0,0,1,6.26-12.52l30.5,15.25a7,7,0,0,1-3.14,13.26Z"
        transform="translate(-60.11 -4.31)"
      />
    </svg>
  );
};

export default Suction;
