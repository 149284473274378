const Schedule = () => {
  return (
    <svg
      id="schedule"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 530 530"
      className="text-primary-content"
    >
      <title>prenotazione</title>
      <path
        className="text-primary-content stroke-[14px]"
        d="M342.72,405.33c-21.21,0-38.68-10.6-46.89-26.66H216.17c-8.21,16.06-25.68,26.66-46.89,26.66-28.65,0-51.95-22.63-51.95-50.43a49.09,49.09,0,0,1,6.38-24.23,49.14,49.14,0,0,1-6.38-24.24c0-27.8,23.3-50.43,52-50.43,21.21,0,38.68,10.6,46.89,26.67h79.66C304,266.6,321.51,256,342.72,256c28.65,0,51.95,22.63,51.95,50.43a49.1,49.1,0,0,1-6.38,24.24,49.13,49.13,0,0,1,6.38,24.23C394.67,382.7,371.37,405.33,342.72,405.33Z"
        transform="translate(9 9)"
        style={{ strokeMiterlimit: 10 }}
      />
      <path
        className="text-primary-content stroke-[18px]"
        d="M448,64H426.67V21.33A21.35,21.35,0,0,0,405.33,0H384a21.34,21.34,0,0,0-21.33,21.33V64H149.33V21.33A21.34,21.34,0,0,0,128,0H106.67A21.35,21.35,0,0,0,85.33,21.33V64H64A64.07,64.07,0,0,0,0,128V448a64.07,64.07,0,0,0,64,64H448a64.07,64.07,0,0,0,64-64V128A64.07,64.07,0,0,0,448,64Zm0,405.33H64A21.33,21.33,0,0,1,42.67,448V192.85H469.33V448A21.33,21.33,0,0,1,448,469.33Z"
        transform="translate(9 9)"
        style={{ strokeMiterlimit: 10 }}
      />
    </svg>
  );
};

export default Schedule;
