import { Section } from "../../types/navbar";

type Props = {
  children: React.ReactNode;
  contrast?: boolean;
  className?: string;
  section?: Section;
};

const FullSection = ({
  children,
  contrast = false,
  className = "",
  section,
}: Props) => {
  return (
    <section className={contrast ? "bg-section" : ""} id={section}>
      <div className={`full-section ${className || ""}`}>{children}</div>
    </section>
  );
};

export default FullSection;
