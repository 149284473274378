import { useMemo } from "react";
import FullSection from "../shared/FullSection";
import Header from "../shared/Header";
import { TestimonialCard } from "../shared/TestimonialCard";
import { BsFacebook } from "react-icons/bs";
import { Section } from "../../types/navbar";
import FbLogo from "../../image/facebook-logo.png";

export const Testimonials = () => {
  const testimonials = useMemo(() => {
    return [
      {
        id: 1,

        title: "Mariana Marinova",
        description:
          "Grazie ad Alfonso-il titolare della Dog Shower Point a Silvi  con la sua disponibilità e gentilezza ci ha aiutato nel nostro primo lavaggio per cani self service e abbiamo potuto trascorrere insieme un momento delicato  con la nostra principessa Dafne 🐶🐾. Assolutamente  consigliato! 🔝",
      },
      {
        id: 2,

        title: "Gabriella Siviero",
        description:
          'Mi sono trovata bene da subito. Locale ben pulito. Il proprietario gentile e cortese.<br />\n\
          Ci torno regolarmente per il mio "piccolo cucciolo"😉<br/>\n\
          Lo consiglio sicuramente.',
      },
      {
        id: 3,

        title: "Marilu' Antonino",
        description:
          "Un’idea davvero brillante per i nostri amati cuccioli!<br />\n\
          La pulizia del posto unita alla praticità del lavaggio e alla cordialità e professionalità del titolare rendono questa esperienza davvero unica! Ci ritornerò sicuramente!",
      },
    ];
  }, []);
  return (
    <FullSection section={Section.Testimonials}>
      <Header text="Dicono di noi..." />
      <div className="grid lg:grid-cols-3 gap-x-6 lg:gap-x-12 mt-12">
        {testimonials.map((testimonial) => (
          <TestimonialCard
            key={testimonial.id}
            source={FbLogo}
            title={testimonial.title}
            description={testimonial.description}
          />
        ))}
      </div>
      <div className="max-w-md flex justify-center mx-auto mt-6 lg:mt-12">
        <a
          href="https://www.facebook.com/DogShowerPointSilvi/reviews"
          target="_blank"
          className="btn btn-outline rounded-xl btn-dark btn-lg"
        >
          <BsFacebook className="mr-2" fontSize={18} />
          Lascia una recensione
        </a>
      </div>
    </FullSection>
  );
};
