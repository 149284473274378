import Hero from "./components/Sections/Hero";
import Navbar from "./components/Navigation/Navbar";
import About from "./components/Sections/About/About";
import Services from "./components/Sections/Services/Services";
import Location from "./components/Sections/Location";
import Fidelity from "./components/Sections/Fidelity";
import { AnimalsSlider } from "./components/Sections/AnimalsSlider";
import { Testimonials } from "./components/Sections/Testimonials";
import { Footer } from "./components/Footer/Footer";
import { LazyMotion, domAnimation } from "framer-motion";

function App() {
  return (
    <div className="App">
      <LazyMotion features={domAnimation}>
        <Navbar />
        <Hero />
        <About />
        <Services />
        <Fidelity />
        <Location />
        <AnimalsSlider />
        <Testimonials />
        <Footer />
      </LazyMotion>
    </div>
  );
}

export default App;
