import HeroImge from "../../image/hero-home.jpg";
import Animals from "../../image/cane-gatto.png";
import WebPAnimals from "../../image/cane-gatto.webp";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useRef } from "react";
import { m } from "framer-motion";

const Hero = () => {
  const ref = useRef(null);

  return (
    <div
      className="hero min-h-[90vh] bg-no-repeat bg-[92%_top] bg-fixed"
      style={{
        backgroundImage: `url(${HeroImge})`,
      }}
      ref={ref}
    >
      <div className="hero-overlay bg-opacity-80" />
      <div className="relative w-full h-full">
        <div className="flex w-full h-full">
          <div className="hero-text-wrapper">
            <m.div
              className="max-w-xl"
              animate={{ opacity: 100 }}
              initial={{ opacity: 0 }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              <h1 className="mb-6 text-4xl sm:text-5xl font-bold leading-[45px]">
                Lavaggio SELF SERVICE per cani e gatti
              </h1>
              <p className="mb-6 sm:text-lg">
                Toelettatura economica e veloce.
                <strong>&nbsp;Dog Shower Point Silvi</strong>, il miglior luogo
                dove lavare il tuo cane.
              </p>

              <a
                href="https://wa.me/+393298798056"
                className="btn btn-primary btn-lg rounded-xl shadow-xl"
                target="__blank"
              >
                <AiOutlineWhatsApp className="mr-2 text-xl" fontSize={18} />
                Richiedi informazioni
              </a>
            </m.div>
          </div>
        </div>
        <m.div
          className="hero-animals"
          animate={{ opacity: 100, x: "50%" }}
          initial={{ opacity: 0, x: "30%" }}
          transition={{ ease: "linear", duration: 1 }}
        >
          <picture>
            <source srcSet={WebPAnimals} type="image/webp" />
            <source srcSet={Animals} type="image/png" />
            <img
              src={Animals}
              alt="Cane gatto che guardano il dog shower point silvi"
              width={250}
              height={233}
            />
          </picture>
        </m.div>
      </div>
    </div>
  );
};

export default Hero;
