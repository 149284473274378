import { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { ChangeEventHandler, useState } from "react";

const useThemeChange = () => {
  // Booleans valued from html data-theme attribute
  const light =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: light)").matches;
  const dark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const initialCheckboxValue = useMemo(() => {
    const hasLocalTheme = !!localStorage.getItem("theme");
    const localDark = hasLocalTheme && localStorage.getItem("theme") === "dark";
    const localLight =
      hasLocalTheme && localStorage.getItem("theme") === "light";
    if (!hasLocalTheme) {
      if (light) {
        return true;
      }
      if (dark) {
        return false;
      }
    }
    if (hasLocalTheme) {
      if (localLight) {
        return true;
      }
      if (localDark) {
        return false;
      }
    }
  }, [dark, light]);

  // Set html data-theme based on dark/light from localstorage or OS color
  useEffect(() => {
    if (dark) {
      document.documentElement.setAttribute("data-theme", "dark");
    }
    if (light) {
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, [dark, light]);

  // Handle toggle theme state
  const [checked, setChecked] = useState(initialCheckboxValue);

  // Handle theme mode via localStorage
  const handleThemeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setChecked(!checked);
    },
    [setChecked, checked]
  );

  return { checked, handleThemeChange };
};

export default useThemeChange;
