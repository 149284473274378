import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

type Props = {
  contrast?: boolean;
};

export const Social = ({ contrast }: Props) => {
  return (
    <div
      className={`flex items-center ${
        contrast ? "text-primary-content" : "text-dark"
      }`}
    >
      <a
        href="https://www.facebook.com/DogShowerPointSilvi"
        target="__blank"
        aria-label="Facebook Dog Shower Point Silvi Marina"
      >
        <FaFacebook className="hover:fill-accent transition-all w-12 h-12 sm:w-7 sm:h-7" />
      </a>
      <a
        className="p-2"
        href="https://www.instagram.com/dog_shower_point_silvi/"
        target="__blank"
        aria-label="Instagram Dog Shower Point Silvi Marina"
      >
        <AiFillInstagram className="hover:fill-accent transition-all pt-[1px] w-14 h-14 sm:w-8 sm:h-8" />
      </a>
    </div>
  );
};
