import Logo from "../../image/logo-dog-shower-point.png";
import WebPLogo from "../../image/logo-dog-shower-point.webp";

import { useEffect } from "react";
import { themeChange } from "theme-change";
import ThemeToggle from "./ThemeToggle";
import { NavLink, Section } from "../../types/navbar";
import Dropdown from "./Dropdown";
import { Link } from "react-scroll";
import { TopBanner } from "./TopBanner";
import { HiPhone } from "react-icons/hi";
import { AiOutlineWhatsApp } from "react-icons/ai";

const navLinks: NavLink[] = [
  {
    title: "Chi siamo",
    section: Section.About,
  },
  {
    title: "Servizi",
    section: Section.Services,
  },
  {
    title: "Fidelity card",
    section: Section.Fidelity,
  },
  {
    title: "Il locale",
    section: Section.Location,
  },
  {
    title: "Gallery",
    section: Section.Gallery,
  },
  {
    title: "Recensioni",
    section: Section.Testimonials,
  },
  {
    title: "Contatti",
    section: Section.Contacts,
  },
];

const Navbar = () => {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <div className="w-full fixed top-0 z-nav">
      <TopBanner />
      <div className="navbar bg-base-100 shadow-md rounded-b-box ">
        <div className="container mx-auto flex xl:px-6 justify-between">
          <Dropdown navLinks={navLinks} />
          <div className="navbar-start hidden xl:block xl:w-auto">
            <a className="hidden xl:block w-[15rem]" href="/">
              <picture>
                <source srcSet={WebPLogo} type="image/webp" />
                <source srcSet={Logo} type="image/png" />
                <img
                  src={Logo}
                  alt="Logo Dog Shower Point Silvi Marina"
                  width={240}
                  height={70}
                />
              </picture>
            </a>
          </div>
          <a className="xl:hidden max-w-[12rem] md:max-w-[15rem]" href="/">
            <picture>
              <source srcSet={WebPLogo} type="image/webp" />
              <source srcSet={Logo} type="image/png" />
              <img
                src={Logo}
                alt="Logo Dog Shower Point Silvi Marina"
                width={240}
                height={70}
              />
            </picture>
          </a>
          <div className="navbar-center hidden xl:flex xl:w-auto">
            <ul className="menu menu-horizontal p-0">
              {navLinks.map((link) => (
                <li key={link.title}>
                  <Link
                    activeClass="nav-link-active"
                    to={link.section}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                    rel="nofollow"
                    href="/"
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="xl:w-auto flex flex-row items-center">
            <div className="flex items-center ml-1 text-dark xl:hidden">
              <a
                href="tel:3298798056"
                className="flex items-center"
                aria-label="Telefono Dog Shower Point Silvi Marina"
              >
                <HiPhone className="text-2xl" />
              </a>

              <a
                href="https://wa.me/+393298798056"
                className="flex items-center pl-4"
                aria-label="Email Dog Shower Point Silvi Marina"
              >
                <AiOutlineWhatsApp className="text-2xl" />
              </a>
            </div>

            <div className="hidden xl:flex items-center ml-1">
              <ThemeToggle />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
