import { Link } from "react-scroll";
import { NavLink } from "../../types/navbar";
import ThemeToggle from "./ThemeToggle";
import { useState } from "react";

type Props = {
  navLinks: NavLink[];
};

const Dropdown = ({ navLinks }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <>
      <div
        className="w-screen h-screen absolute top-0 left-0"
        style={{ display: isDropdownOpen ? "flex" : "none" }}
        onClick={toggleDropdown}
      ></div>

      <div className="dropdown xl:hidden">
        <label
          tabIndex={0}
          className="btn btn-ghost xl:hidden"
          onClick={toggleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </label>

        <ul
          tabIndex={0}
          className="menu dropdown-content mt-3 md:mt-5 p-2 shadow bg-base-100 rounded-box w-64 z-100"
          style={{ display: isDropdownOpen ? "flex" : "none" }}
        >
          {navLinks.map((link) => (
            <li key={link.title}>
              <Link
                activeClass="nav-link-active"
                to={link.section}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleDropdown}
                className="nav-link"
                rel="nofollow"
                href="/"
              >
                {link.title}
              </Link>
            </li>
          ))}
          <li className="xl:hidden">
            <ThemeToggle />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Dropdown;
