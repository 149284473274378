type Props = {
  text: string;
  contrast?: boolean;
  className?: string;
  subtitle?: string;
};

const Header = ({
  text,
  className = "",
  contrast = false,
  subtitle = "",
}: Props) => {
  return (
    <div>
      <div
        className={`flex flex-col justify-center items-center lg:flex-row ${
          className || ""
        }`}
      >
        <div className="w-[25px] h-[25px] mb-2 lg:flex lg:items-center lg:mb-0 lg:mr-[20px]">
          <svg
            id="Capa_1"
            data-name="Capa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 440.19"
            className={`text-2xl lg:text-4xl${
              contrast ? " fill-primary-content" : " fill-info"
            }`}
          >
            <title>orma cane</title>
            <path
              d="M378.61,291c-34.45-41.59-78-64.49-122.61-64.49S167.84,249.4,133.39,291C102.43,328.38,84,375.5,84,417c0,20.18,6.16,35.54,18.33,45.68,12,10,27.85,13.39,45.54,13.39,18.82,0,39.71-3.84,60.24-7.6,17.94-3.29,34.88-6.4,47.94-6.4,11.27,0,27.17,2.95,44,6.07,39.32,7.3,83.88,15.57,109.62-5.83,12.24-10.18,18.45-25.42,18.45-45.31C428.06,375.49,409.57,328.38,378.61,291Z"
              transform="translate(0 -35.9)"
            />
            <path
              d="M382.13,65.15c-12.6-18.59-30.65-29.24-49.52-29.24S295.7,46.56,283.1,65.15c-11.58,17.07-18,39.49-18,63.12s6.38,46,18,63.13c12.6,18.58,30.65,29.24,49.51,29.24S369.53,210,382.13,191.4c11.58-17.08,17.95-39.49,17.95-63.13S393.71,82.22,382.13,65.15Z"
              transform="translate(0 -35.9)"
            />
            <path
              d="M228.9,65.14C216.3,46.56,198.25,35.9,179.39,35.9s-36.92,10.66-49.52,29.24c-11.58,17.08-18,39.49-18,63.13s6.37,46,18,63.12c12.61,18.59,30.65,29.25,49.52,29.25S216.3,210,228.9,191.39c11.58-17.07,18-39.48,18-63.12S240.48,82.21,228.9,65.14Z"
              transform="translate(0 -35.9)"
            />
            <path
              d="M509,210.13c-5.08-17.28-16-29.84-30.67-35.36-11.82-4.44-25.17-3.69-37.58,2.12-17.1,8-31.09,24.53-38.35,45.35-5.95,17-6.69,35-2.09,50.62,5.08,17.28,16,29.84,30.67,35.36a45.09,45.09,0,0,0,15.9,2.86,51.16,51.16,0,0,0,21.68-5c17.11-8,31.09-24.53,38.36-45.34h0C512.88,243.75,513.62,225.77,509,210.13Z"
              transform="translate(0 -35.9)"
            />
            <path
              d="M109.59,222.24c-7.27-20.82-21.26-37.34-38.36-45.35-12.41-5.81-25.76-6.56-37.58-2.12C19,180.29,8.06,192.85,3,210.13c-4.6,15.64-3.85,33.62,2.09,50.62h0c7.27,20.81,21.25,37.34,38.35,45.34a51.23,51.23,0,0,0,21.68,5A45.14,45.14,0,0,0,81,308.22c14.69-5.52,25.58-18.08,30.66-35.36C116.27,257.22,115.53,239.24,109.59,222.24Z"
              transform="translate(0 -35.9)"
            />
          </svg>
        </div>
        <h2
          className={`text-3xl lg:text-4xl${
            contrast ? " text-primary-content" : ""
          }`}
        >
          {text}
        </h2>
      </div>
      <p
        className={`text-center mt-6${contrast ? " text-primary-content" : ""}`}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default Header;
