import Thumb1 from "../image/thumbnails/thumb-1.jpg";
import Thumb2 from "../image/thumbnails/thumb-2.jpg";
import Thumb3 from "../image/thumbnails/thumb-3.jpg";
import Thumb4 from "../image/thumbnails/thumb-4.jpg";
import Thumb5 from "../image/thumbnails/thumb-5.jpg";
import Thumb6 from "../image/thumbnails/thumb-6.jpg";
import Thumb7 from "../image/thumbnails/thumb-7.jpg";
import Thumb8 from "../image/thumbnails/thumb-8.jpg";
import Thumb9 from "../image/thumbnails/thumb-9.jpg";
import Thumb10 from "../image/thumbnails/thumb-10.jpg";
import Thumb11 from "../image/thumbnails/thumb-11.jpg";
import Thumb12 from "../image/thumbnails/thumb-12.jpg";
import Thumb13 from "../image/thumbnails/thumb-13.jpg";
import Thumb14 from "../image/thumbnails/thumb-14.jpg";
import Thumb15 from "../image/thumbnails/thumb-15.jpg";
import Thumb16 from "../image/thumbnails/thumb-16.jpg";
import Thumb17 from "../image/thumbnails/thumb-17.jpg";
import Thumb18 from "../image/thumbnails/thumb-18.jpg";
import Thumb19 from "../image/thumbnails/thumb-19.jpg";
import Thumb20 from "../image/thumbnails/thumb-20.jpg";
import Thumb21 from "../image/thumbnails/thumb-21.jpg";
import Thumb22 from "../image/thumbnails/thumb-22.jpg";
import Thumb23 from "../image/thumbnails/thumb-23.jpg";
import Thumb24 from "../image/thumbnails/thumb-24.jpg";
import Thumb25 from "../image/thumbnails/thumb-25.jpg";
import Thumb26 from "../image/thumbnails/thumb-26.jpg";
import Thumb27 from "../image/thumbnails/thumb-27.jpg";
import Thumb28 from "../image/thumbnails/thumb-28.jpg";
import Thumb29 from "../image/thumbnails/thumb-29.jpg";
import Thumb30 from "../image/thumbnails/thumb-30.jpg";
import Thumb31 from "../image/thumbnails/thumb-31.jpg";
import Thumb32 from "../image/thumbnails/thumb-32.jpg";
import Thumb33 from "../image/thumbnails/thumb-33.jpg";
import Thumb34 from "../image/thumbnails/thumb-34.jpg";
import Thumb35 from "../image/thumbnails/thumb-35.jpg";
import Thumb36 from "../image/thumbnails/thumb-36.jpg";
import Thumb37 from "../image/thumbnails/thumb-37.jpg";
import Thumb38 from "../image/thumbnails/thumb-38.jpg";
import Thumb39 from "../image/thumbnails/thumb-39.jpg";
import Thumb40 from "../image/thumbnails/thumb-40.jpg";
import { useMemo } from "react";

export const useThumbnails = () => {
  return useMemo(() => {
    return [
      Thumb1,
      Thumb2,
      Thumb3,
      Thumb4,
      Thumb5,
      Thumb6,
      Thumb7,
      Thumb8,
      Thumb9,
      Thumb10,
      Thumb11,
      Thumb12,
      Thumb13,
      Thumb14,
      Thumb15,
      Thumb16,
      Thumb17,
      Thumb18,
      Thumb19,
      Thumb20,
      Thumb21,
      Thumb22,
      Thumb23,
      Thumb24,
      Thumb25,
      Thumb26,
      Thumb27,
      Thumb28,
      Thumb29,
      Thumb30,
      Thumb31,
      Thumb32,
      Thumb33,
      Thumb34,
      Thumb35,
      Thumb36,
      Thumb37,
      Thumb38,
      Thumb39,
      Thumb40,
    ];
  }, []);
};
