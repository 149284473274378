import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Header from "../shared/Header";
import { MdLocationOn } from "react-icons/md";
import Slider1 from "../../image/slider1.jpg";
import Slider2 from "../../image/slider2.jpg";
import Slider3 from "../../image/slider3.jpg";
import Slider4 from "../../image/slider4.jpg";
import FullSection from "../shared/FullSection";
import { Section } from "../../types/navbar";
import LazyLoad from "react-lazyload";

const Location = () => {
  return (
    <FullSection section={Section.Location}>
      <Header
        text="Visita il nostro locale"
        subtitle="Scorri le immagini per visitare il Dog Shower Point"
      />
      <div className="mt-16">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          navigation={false}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="swiper-container"
        >
          <SwiperSlide className="w-full">
            <div className="w-full">
              <LazyLoad>
                <img
                  src={Slider1}
                  className="max-w-full rounded-xl border-2 border-primary"
                  alt="Panoramica entrata Dog Shower Point Silvi con vasche per lavaggio"
                />
              </LazyLoad>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full">
            <div className="w-full">
              <LazyLoad>
                <img
                  src={Slider2}
                  className="max-w-full rounded-xl border-2 border-primary"
                  alt="Vasca lavaggio cane del Dog Shower Point Silvi"
                />
              </LazyLoad>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full">
            <div className="w-full">
              <LazyLoad>
                <img
                  src={Slider3}
                  className="max-w-full rounded-xl border-2 border-primary"
                  alt="Area lavaggio cane con cane in vasca nel Dog Shower Point Silvi"
                />
              </LazyLoad>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full">
            <div className="w-full">
              <LazyLoad>
                <img
                  src={Slider4}
                  className="max-w-full rounded-xl border-2 border-primary"
                  alt="Dog Shower Point Silvi ambiente esterno"
                />
              </LazyLoad>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="max-w-md flex justify-center mx-auto mt-6 lg:mt-12">
        <a
          href="https://www.google.it/maps/place/Dog+Shower+Point/@42.5430056,14.1210121,17z/data=!3m1!4b1!4m5!3m4!1s0x1331a31a760784c7:0x4059a946771d9f87!8m2!3d42.5430017!4d14.1232008"
          target="__blank"
          className="btn btn-outline rounded-xl btn-dark btn-lg"
        >
          <MdLocationOn className="mr-2" fontSize={18} />
          Vieni a trovarci
        </a>
      </div>
    </FullSection>
  );
};

export default Location;
